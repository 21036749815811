import _ from "lodash";
import {
  Org,
  OrgRegistrationPackage,
  OrgRegistrationQuestion,
  OrgRegistrationQuestionType,
  OrgRegistrationQuestionZod,
  OrgRegistrationQuestion__Checkbox,
  OrgRegistrationQuestion__Explainer
} from "@ollie-sports/models";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { openModal } from "../../components/modals/imperativeModal";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import {
  Form,
  PrettyCoolTextInput,
  PrettyCoolTextInputWithLabel,
  PrettyCoolSelectInput,
  PrettyCheckbox
} from "../../components/Form";
import { useEffect, useRef, useState } from "react";
import { FormRef } from "../../components/Form";
import { translate } from "@ollie-sports/i18n";
import { useImmutableState } from "../../utils/useImmutableState";
import { registrationQuestionTypeLabels } from "../../constants";
import { ObjectKeys, api, getOrgRegistrationQuestionMainBodyText } from "@ollie-sports/core";
import { SafeButton } from "../../components/SafeButton";
import { PlusIcon } from "@heroicons/react/20/solid";
import { InformationCircleIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { TextButton } from "../../components/TextButton";
import { getBifrost } from "../../services/bifrost.service";
import { Tooltip } from "@material-ui/core";
import { InfoTooltipIcon } from "../../components/InfoTooltip";
import { Tag } from "../orgRegistrationPackages/OrgRegistrationPackagesList";
import { EditButton } from "../../components/EditButton";
import { openErrorToast } from "../../utils/openErrorToast";

type Props = {
  type: "create" | "edit";
  org: Org;
  intialOrgQuestion?: Partial<OrgRegistrationQuestion>;
};

export function openAddEditOrgRegistrationQuestion(p: Props) {
  return new Promise<void>(res => {
    const modal = openModal({
      body: (
        <AddEditOrgRegistrationQuestion
          {...p}
          onRequestDismiss={() => {
            modal.close();
            res();
          }}
        />
      )
    });
  });
}

function AddEditOrgRegistrationQuestion(p: Props & { onRequestDismiss: () => void }) {
  const { data: orgQuestionGroupings } =
    getBifrost().orgRegistrationQuestionGrouping__client__getAllForOrgIdSubscription.useClientSubscription({ orgId: p.org.id });

  const formRef = useRef<FormRef>(null);
  const [orgQuestion, modifyOrgQuestion] = useImmutableState(
    p.intialOrgQuestion ??
      ({
        options: [{ valueMD: "" }, { valueMD: "" }],
        isRequired: false,
        showAfterPayment: false
      } as Partial<OrgRegistrationQuestion>)
  );

  const [shadowQuestionText, setShadowQuestionText] = useState(
    p.intialOrgQuestion ? getOrgRegistrationQuestionMainBodyText(p.intialOrgQuestion) : ""
  );

  const questionOptions =
    orgQuestion.type === OrgRegistrationQuestionType.checkbox || orgQuestion.type === OrgRegistrationQuestionType.radio
      ? orgQuestion.options || []
      : null;

  const assignedOrgQuestionGroupings = orgQuestionGroupings?.filter(a => a.questionIds[orgQuestion.id || ""]?.exists) ?? [];

  return (
    <Form ref={formRef}>
      {isValid => (
        <FullScreenModal
          title={
            p.type === "edit" ? translate({ defaultMessage: "Edit Question" }) : translate({ defaultMessage: "Create Question" })
          }
          onRequestDismiss={p.onRequestDismiss}
          bottomButton={{
            onPress: async () => {
              if (!formRef.current?.validate() || !orgQuestion.type) {
                return;
              }

              const finalQuestion =
                p.type === "edit"
                  ? OrgRegistrationQuestionZod.parse({
                      ...orgQuestion,
                      orgId: p.org.id,
                      questionMD: shadowQuestionText,
                      textMD: shadowQuestionText,
                      descriptionMD: shadowQuestionText
                    })
                  : OrgRegistrationQuestionZod.parse({
                      ...orgQuestion,
                      orgId: p.org.id,
                      questionMD: shadowQuestionText,
                      textMD: shadowQuestionText,
                      descriptionMD: shadowQuestionText,
                      createdAtMS: Date.now(),
                      id: api.common__client__getGenerator().OrgRegistrationQuestion()
                    });

              try {
                if (p.type === "create") {
                  await getBifrost().orgRegistrationQuestion__client__add.fetchClient({
                    doc: finalQuestion
                  });
                } else {
                  await getBifrost().orgRegistrationQuestion__client__update.fetchClient({
                    id: p.intialOrgQuestion!.id!,
                    shallowChanges: _.omit(finalQuestion, ["id", "createdAtMS"])
                  });
                }
                p.onRequestDismiss();
              } catch (e) {
                openErrorToast(
                  p.type === "create"
                    ? translate({
                        defaultMessage:
                          "There was a problem creating the question. Please try again or contact support@olliesports.com"
                      })
                    : translate({
                        defaultMessage:
                          "There was a problem editing the question. Please try again or contact support@olliesports.com"
                      })
                );
              }
            },
            title: p.type === "edit" ? translate.common.Save : translate.common.Create
          }}
        >
          <PrettyCoolSelectInput
            label={translate({ defaultMessage: "Question Type" })}
            value={orgQuestion.type}
            placeholder={translate({ defaultMessage: "Select..." })}
            onChange={newType => {
              modifyOrgQuestion({ type: newType as any });
            }}
            isRequired
            options={ObjectKeys(registrationQuestionTypeLabels).map(k => ({
              label: registrationQuestionTypeLabels[k],
              value: k
            }))}
          />

          {orgQuestion.type === OrgRegistrationQuestionType.additionalStep ? (
            <div className="mt-2">
              <label className="text-xs text-gray-500">
                {translate({
                  defaultMessage: `Extra registration steps for each player that can ONLY be checked off by a team or club admin. Parents/players will only see the steps AFTER they have paid. A great use case for this is to track which players have completed a required external league registration.`
                })}
              </label>
            </div>
          ) : null}

          <PrettyCoolTextInputWithLabel
            value={orgQuestion.shortTitle}
            style={{ marginTop: 16 }}
            label={translate({ defaultMessage: "Short Title" })}
            inputProps={{
              maxLength: 30
            }}
            isRequired
            placeholder={
              orgQuestion.type === OrgRegistrationQuestionType.freeResponse
                ? translate({ defaultMessage: "E.g. List allergies" })
                : orgQuestion.type === OrgRegistrationQuestionType.radio
                ? translate({ defaultMessage: "E.g. Preferred Position" })
                : orgQuestion.type === OrgRegistrationQuestionType.checkbox
                ? translate({ defaultMessage: "E.g. Playable Positions" })
                : orgQuestion.type === OrgRegistrationQuestionType.additionalStep
                ? translate({ defaultMessage: "E.g. State League Registration" })
                : translate({ defaultMessage: "E.g. USYS Waiver" })
            }
            onChange={newVal => {
              modifyOrgQuestion({ shortTitle: newVal });
            }}
          />

          {orgQuestion.type === OrgRegistrationQuestionType.legalDoc ? (
            <PrettyCoolTextInputWithLabel
              style={{ marginTop: 16 }}
              value={orgQuestion.documentName}
              placeholder={translate({ defaultMessage: "E.g. USYS Parent Guardian Consent & Medical Release" })}
              label={translate({ defaultMessage: "Document Name" })}
              isRequired
              onChange={newVal => {
                modifyOrgQuestion({ documentName: newVal });
              }}
            />
          ) : null}
          <PrettyCoolTextInputWithLabel
            value={shadowQuestionText}
            style={{ marginTop: 16 }}
            inputProps={{
              style: { minHeight: 100 },
              className: "max-h-60"
            }}
            placeholder={
              !orgQuestion.type || orgQuestion.type === OrgRegistrationQuestionType.freeResponse
                ? translate({ defaultMessage: "E.g. What food allergies does the player have?" })
                : orgQuestion.type === OrgRegistrationQuestionType.checkbox
                ? translate({ defaultMessage: "E.g. Please select all playable positions" })
                : orgQuestion.type === OrgRegistrationQuestionType.radio
                ? translate({ defaultMessage: "E.g. What is the player's preferred position?" })
                : orgQuestion.type === OrgRegistrationQuestionType.legalDoc
                ? translate({ defaultMessage: "Enter legal text here..." })
                : orgQuestion.type === OrgRegistrationQuestionType.additionalStep
                ? translate({ defaultMessage: "Enter description here..." })
                : translate({ defaultMessage: "Enter supplemental information here..." })
            }
            isRequired
            multiline
            label={
              orgQuestion.type === OrgRegistrationQuestionType.explainer
                ? translate({ defaultMessage: "Text" })
                : orgQuestion.type === OrgRegistrationQuestionType.legalDoc
                ? translate({ defaultMessage: "Document Body" })
                : orgQuestion.type === OrgRegistrationQuestionType.additionalStep
                ? translate.common.Description
                : translate({ defaultMessage: "Question" })
            }
            onChange={newVal => {
              setShadowQuestionText(newVal || "");
            }}
          />
          {orgQuestion.type &&
          orgQuestion.type !== OrgRegistrationQuestionType.explainer &&
          orgQuestion.type !== OrgRegistrationQuestionType.additionalStep ? (
            <PrettyCheckbox
              label={translate({ defaultMessage: "Is Required?" })}
              checked={!!(orgQuestion as any).isRequired}
              onChange={() => {
                modifyOrgQuestion({ isRequired: !(orgQuestion as any).isRequired } as any);
              }}
            />
          ) : null}

          {questionOptions ? (
            <div className="mt-2">
              <h3 className="text-sm font-extrabold mb-1">{translate({ defaultMessage: "Question Responses" })}</h3>
              {questionOptions.map((opt, i) => (
                <div key={i} className="flex mb-2">
                  <div className="flex mt-2.5">
                    <div className="w-5 h-5 text-center rounded-full bg-gray-300 mr-3">
                      <span className="text-white font-bold text-xs">{i + 1}</span>
                    </div>
                  </div>
                  <div className="flex-1">
                    <PrettyCoolTextInput
                      key={i}
                      value={opt.valueMD}
                      isRequired
                      placeholder={
                        i === 0
                          ? translate({ defaultMessage: "E.g. Midfielder" }) + "..."
                          : translate({ defaultMessage: "E.g. Striker" }) + "..."
                      }
                      onChange={newVal => {
                        modifyOrgQuestion(draftRaw => {
                          const draft: OrgRegistrationQuestion__Checkbox = draftRaw as any;
                          draft.options[i].valueMD = newVal || "";
                        });
                      }}
                    />
                  </div>
                  <button
                    className="self-start mt-2"
                    onClick={() => {
                      modifyOrgQuestion(draftRaw => {
                        const draft: OrgRegistrationQuestion__Checkbox = draftRaw as any;
                        draft.options.splice(i, 1);
                      });
                    }}
                    disabled={i < 2}
                    style={{ opacity: i < 2 ? 0 : 1 }}
                  >
                    <TrashIcon className="ml-2 w-6 h-6 text-red-300" />
                  </button>
                </div>
              ))}
              <TextButton
                className="mt-2"
                iconElm={<PlusIcon width={24} height={24} className="text-green-400" />}
                text={translate({ defaultMessage: "Add Option" })}
                textClassName="text-green-400"
                onPress={() => {
                  modifyOrgQuestion(draftRaw => {
                    const draft: OrgRegistrationQuestion__Checkbox = draftRaw as any;
                    draft.options = draft.options || [];
                    draft.options.push({ valueMD: "" });
                  });
                }}
              />
            </div>
          ) : null}

          {assignedOrgQuestionGroupings.length ? (
            <>
              <h3 className="text-sm font-extrabold mb-1 mt-4 flex">
                {translate({ defaultMessage: "Question Group Assignments" })}
              </h3>

              <div className="flex flex-wrap gap-2">
                {assignedOrgQuestionGroupings.map((a, i) => (
                  <div key={i} className="text-white p-2 rounded-xl bg-gray-400">
                    {a.name}
                  </div>
                ))}
              </div>
            </>
          ) : null}
        </FullScreenModal>
      )}
    </Form>
  );
}
