import _ from "lodash";
import { Box, Button, SvgIcon } from "@material-ui/core";
import { View } from "react-native-web";
import {
  ORG_PERMISSIONS,
  Org,
  OrgRegistrationPackage,
  OrgRegistrationQuestion,
  OrgRegistrationQuestionGrouping
} from "@ollie-sports/models";
import { CenteredLoader } from "../../components/CenteredLoader";
import { translate } from "@ollie-sports/i18n";
import { useParams } from "react-router-dom";
import { getBifrost } from "../../services/bifrost.service";

import CoolerTable from "../../components/CoolerTable";
import { openAddEditOrgRegistrationQuestion } from "./AddEditOrgRegistrationQuestion";
import { PlusCircleIcon, DocumentCheckIcon, QuestionMarkCircleIcon, RectangleGroupIcon } from "@heroicons/react/24/outline";

import { CoolerTabbedContent } from "../../components/CoolerTabbedContent";
import { RegistrationQuestionGroupingsTable } from "./RegistrationQuestionGroupingsTable";
import { RegistrationQuestionsTable } from "./RegistrationQuestionsTable";
import { compute } from "@ollie-sports/core";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";
import { useSearchParamsState } from "../../hooks/useSearchParamsState";
import { RegistrationResponses } from "./RegistrationResponses";

export default function OrgRegistrationQuestionsList() {
  const params: any = useParams();
  const orgId = params.orgId;

  const { data: orgQuestionGroupings } =
    getBifrost().orgRegistrationQuestionGrouping__client__getAllForOrgIdSubscription.useClientSubscription({ orgId });

  const { data: orgPackages } =
    getBifrost().orgRegistrationPackage__client__getOrgRegistrationPackagesForOrgSubscription.useClientSubscription({ orgId });

  const { data: org } = getBifrost().org__client__getOrgSubscription.useClientSubscription(
    { orgId },
    { notifyOnMetaDataChanges: true }
  );

  const { data: orgQuestions } = getBifrost().orgRegistrationQuestion__client__getAllForOrgIdSubscription.useClientSubscription({
    orgId
  });

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {!orgQuestions || !org || !orgPackages || !orgQuestionGroupings ? (
          <CenteredLoader />
        ) : (
          <OrgRegistrationQuestionsListInner
            org={org}
            orgQuestions={orgQuestions}
            orgPackages={orgPackages}
            orgQuestionGroupings={orgQuestionGroupings}
          />
        )}
      </View>
    </Box>
  );
}

function OrgRegistrationQuestionsListInner(p: {
  org: Org;
  orgQuestions: OrgRegistrationQuestion[];
  orgQuestionGroupings: OrgRegistrationQuestionGrouping[];
  orgPackages: OrgRegistrationPackage[];
}) {
  const [searchParams, setSearchParams] = useSearchParamsState<{ tab?: string }>();
  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: "row" }}>
        <h1 className="flex-1 text-2xl sm:text-4xl mt-4">{translate({ defaultMessage: "Registration Questions" })}</h1>
        {compute.org.hasOrgPermission({
          accountId: getCurrentUserAccountId(),
          org: p.org,
          permission: ORG_PERMISSIONS.manageFinances
        }) ? (
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              openAddEditOrgRegistrationQuestion({
                org: p.org,
                type: "create"
              });
            }}
          >
            <SvgIcon style={{ paddingRight: 6 }}>
              <PlusCircleIcon />
            </SvgIcon>
            {translate({ defaultMessage: "New Question" })}
          </Button>
        ) : null}
      </View>

      <CoolerTabbedContent
        className="py-8"
        useQueryParams
        initialTabKey={searchParams.tab}
        tabs={[
          {
            key: "question",
            label: translate({ defaultMessage: "Questions" }),
            labelIcon: <QuestionMarkCircleIcon />,
            getContents: () => <RegistrationQuestionsTable {...p} />
          },
          {
            key: "groupings",
            label: translate({ defaultMessage: "Question Groups" }),
            labelIcon: <RectangleGroupIcon />,
            getContents: () => <RegistrationQuestionGroupingsTable {...p} />
          },
          {
            key: "responses",
            label: translate({ defaultMessage: "Responses" }),
            labelIcon: <DocumentCheckIcon />,
            getContents: () => <RegistrationResponses org={p.org} />
          }
        ]}
      />
    </View>
  );
}
