import { OrgRegistrationQuestion, OrgRegistrationQuestionId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function orgRegistrationQuestion__client__getRelatedDocs(p: { id: OrgRegistrationQuestionId; orgId: string }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const [packages, packageGroups] = await Promise.all([
    h.OrgRegistrationPackage.query({
      where: [{ questionIds: { [p.id]: { exists: ["==", true] } } }, { orgId: ["==", p.orgId] }]
    }).then(a => a.docs),
    h.OrgRegistrationQuestionGrouping.query({
      where: [{ questionIds: { [p.id]: { exists: ["==", true] } } }, { orgId: ["==", p.orgId] }]
    }).then(a => a.docs)
  ]);

  return { packages, packageGroups };
}
