import { OrgId, OrgRegistrationQuestionId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { orgRegistrationQuestion__client__getRelatedDocs } from "./orgRegistrationQuestion__getRelatedDocs";

export async function orgRegistrationQuestion__client__delete(p: { id: OrgRegistrationQuestionId; orgId: OrgId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const { packageGroups, packages } = await orgRegistrationQuestion__client__getRelatedDocs(p);

  await h._BatchRunner.executeBatch([
    await h.OrgRegistrationQuestion.delete(
      {
        id: p.id
      },
      { returnBatchTask: true }
    ),
    ...(await Promise.all(
      packages.map(pkg => {
        return h.OrgRegistrationPackage.update(
          { id: pkg.id, doc: { questionIds: { [p.id]: h._MagicDeleteValue } } },
          { returnBatchTask: true }
        );
      })
    )),
    ...(await Promise.all(
      packageGroups.map(pkg => {
        return h.OrgRegistrationQuestionGrouping.update(
          { id: pkg.id, doc: { questionIds: { [p.id]: h._MagicDeleteValue } } },
          { returnBatchTask: true }
        );
      })
    ))
  ]);
}
