import { OrgRegistrationQuestion } from "@ollie-sports/models";
import _ from "lodash";
import { getUniversalHelpers } from "../../helpers";
import { translate } from "@ollie-sports/i18n";
import { DistributiveOmit } from "../../utils";

export async function orgRegistrationQuestion__client__add(p: { doc: OrgRegistrationQuestion }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.OrgRegistrationQuestion.add({
    doc: p.doc
  });
}

// i18n certified - complete
