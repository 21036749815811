import { getUniversalHelpers } from "../../helpers";
import { Org, OrgId, OrgPaymentPlan, OrgTeamTag } from "@ollie-sports/models";
import { firestoreLiftQuerySubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function orgRegistrationQuestion__client__getAllForOrgIdSubscription(p: { orgId: OrgId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return firestoreLiftQuerySubToBifrostSub(
    h.OrgRegistrationQuestion.querySubscription({
      where: [{ orgId: ["==", p.orgId] }]
    })
  );
}
