import {
  OrgRegistrationId,
  OrgRegistrationPackageId,
  OrgRegistrationQuestion,
  OrgRegistrationQuestionId,
  OrgRegistrationQuestionType,
  OrgRegistrationQuestion__AdditionalStep
} from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { getOrgRegistrationQuestionInfoForRegistrationPackage } from "../orgInvoice/org-invoice-helpers";
import express from "express";
import { validateToken } from "../../internal-utils/server-auth";

export async function orgRegistrationQuestion__server__getAdditionalStepsForOrgRegistration(p: {
  orgRegistrationId: OrgRegistrationId;
  orgRegistrationPackageId: OrgRegistrationPackageId;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const [orgRegistration, orgRegistrationPackage] = await Promise.all([
    h.OrgRegistration.getDoc(p.orgRegistrationId),
    h.OrgRegistrationPackage.getDoc(p.orgRegistrationPackageId)
  ]);

  if (!orgRegistration || !orgRegistrationPackage) {
    return [];
  }

  const orgRegistrationQuestionInfo = await getOrgRegistrationQuestionInfoForRegistrationPackage(orgRegistrationPackage);

  const additionalStepQuestions = (
    orgRegistrationQuestionInfo.orgRegistrationQuestions.filter(
      q => q.type === OrgRegistrationQuestionType.additionalStep
    ) as OrgRegistrationQuestion__AdditionalStep[]
  ).filter(
    q => !orgRegistration.completedAllAdditionalStepsAtMS || q.createdAtMS < orgRegistration.completedAllAdditionalStepsAtMS
  );

  return additionalStepQuestions;
}

orgRegistrationQuestion__server__getAdditionalStepsForOrgRegistration.auth = async (r: express.Request) => {
  await validateToken(r);
};
