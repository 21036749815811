import { PencilIcon, TrashIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import { Button, SvgIcon } from "@material-ui/core";
import { COLORS, compute } from "@ollie-sports/core";
import { dateFormatters, getCurrentLocale, translate } from "@ollie-sports/i18n";
import _ from "lodash";
import CoolerTable from "../../components/CoolerTable";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import getConfirm from "../../components/modals/getConfirm";
import { getBifrost } from "../../services/bifrost.service";
import { wrapPromiseWithLoader } from "../../utils/wrapPromiseWithLoader";
import { openOrgRegistrationQuestionGroupingAddEditModal } from "./AddEditOrgRegistrationQuestionGrouping";
import {
  ORG_PERMISSIONS,
  Org,
  OrgRegistrationAnswerTableData,
  OrgRegistrationQuestion,
  OrgRegistrationQuestionGrouping,
  OrgRegistrationQuestionType,
  OrgRegistrationTableData
} from "@ollie-sports/models";
import { useState } from "react";
import { getCurrentUserAccountId, useCurrentUserAccount } from "../../hooks/commonDataUtils";
import { usePersistentState } from "../../utils/usePersistentState";
import { useOrgSeasons } from "../../hooks/useOrgSeasons";
import { CoolSelectInput } from "../../components/Inputs/CoolSelectInput";
import { AsyncFancyTable, ColumnDef } from "../../components/AsyncFancyTable";
import moment from "moment";
import { ActionButtonDropdown } from "../../components/ActionButtonDropdown";
import { MoreHorizontal } from "react-feather";
import { openErrorToast, openSuccessToast } from "../../utils/openErrorToast";
import { useOrgCurrentSeasonId } from "../../utils/useOrgCurrentSeasonId";

export function RegistrationResponses(p: { org: Org }) {
  const [selectedOrgSeasonId, setSelectedOrgSeasonId] = useOrgCurrentSeasonId(p.org.id);

  const { data: account } = useCurrentUserAccount();
  const [isFetching, setIsFetching] = useState(false);
  const [allQuestionShortTitles, setAllQuestionShortTitles] = useState<string[]>([]);

  const [selectedPlayersByPlayerBundleId, setSelectedPlayersByPlayerBundleId] = usePersistentState({
    initialValue: {} as Record<string, true>,
    key: "org-registration-answers-selected-players" + p.org.id
  });
  const numSelectedPlayers = Object.keys(selectedPlayersByPlayerBundleId).length;

  const actionOptions = _.compact([
    numSelectedPlayers && selectedOrgSeasonId
      ? {
          key: "export",
          label: () => translate({ defaultMessage: "Export {num} Players" }, { num: numSelectedPlayers }),
          onClick: async () => {
            try {
              if (!account) {
                return;
              }
              const confirm = await getConfirm({
                title: translate({ defaultMessage: "Export Responses?" }),
                subtitle: translate({ defaultMessage: "Do you want to send an export to {email}?" }, { email: account.email }),
                confirmButtonColor: "blue"
              });
              if (confirm) {
                await wrapPromiseWithLoader(
                  getBifrost().reports__server__exportOrgRegistrationAnswers.fetchServer({
                    orgId: p.org.id,
                    orgSeasonId: selectedOrgSeasonId,
                    playerBundleIds: Object.keys(selectedPlayersByPlayerBundleId),
                    selfAccountId: getCurrentUserAccountId(),
                    selfEmail: account.email
                  })
                );
                openSuccessToast();
              }
            } catch (e) {
              openErrorToast(translate.common.SomethingWentWrong);
            }
          }
        }
      : null
  ]);

  const orgSeasons = useOrgSeasons({ orgId: p.org.id }) ?? [];
  return (
    <div className="w-full">
      <CoolSelectInput
        label={translate.common.Season}
        containerStyle={{ marginTop: 30 }}
        placeholder={translate({ defaultMessage: "Select Season" })}
        value={selectedOrgSeasonId}
        inputProps={{
          style: { fontSize: 20, height: 60, minWidth: 280, width: "auto" }
        }}
        options={orgSeasons
          .filter(os => !os.archivedAtMS)
          .map(os => {
            return {
              label: os.name,
              value: os.id
            };
          })}
        onChange={newVal => {
          if (numSelectedPlayers) {
            const yes = window.confirm(
              translate({
                defaultMessage: "You have selected players. Do you wish to clear your selection and change seasons?"
              })
            );
            if (!yes) {
              return;
            }
            setSelectedPlayersByPlayerBundleId({});
          }
          setSelectedOrgSeasonId(newVal);
        }}
      />
      {selectedOrgSeasonId ? (
        <AsyncFancyTable
          getRowOptions={(a: OrgRegistrationAnswerTableData) => ({})} //This line here to hint typescript...
          selectRowOptions={{
            selectedItemsByKey: selectedPlayersByPlayerBundleId,
            onUpdate: items => {
              setSelectedPlayersByPlayerBundleId(items);
            },
            selectAllQuestionText: currData =>
              translate(
                {
                  defaultMessage:
                    "Select all {num} players? To select only the players on this page, select the first row, hold down the shift key, and then click the last row."
                },
                { num: currData.totalNumberOfItemsMatchingCriteria }
              )
          }}
          className="mt-4 w-full"
          onIsFetchingUpdate={setIsFetching}
          getRowKey={(it: OrgRegistrationAnswerTableData) => it.playerBundleId} //This HAS to stay as the player bundle id. Do NOT change.
          pagination={{ initialPageSize: 25, pageSizes: [25, 50, 100, 150] }}
          dataCachingKey={"registration-answers-table-" + p.org.id}
          fetchItems={info => {
            return getBifrost()
              .reports__server__getOrgRegistrationAnswers.fetchServer(
                {
                  orgId: p.org.id,
                  orgSeasonId: selectedOrgSeasonId,
                  offset: info.pagination.numItemsPerPage * (info.pagination.page - 1),
                  limit: info.pagination.numItemsPerPage,
                  sort: info.sort,
                  search: info.filters?.search
                },
                { enabled: !!selectedOrgSeasonId }
              )
              .then(a => {
                const allQuestionShortTitles = Object.keys(
                  a.data.data.reduce((acc, val) => {
                    val.answers.forEach(answer => {
                      acc[answer.questionSnapshot.shortTitle] = true;
                    });
                    return acc;
                  }, {} as Record<string, true>)
                );
                setAllQuestionShortTitles(allQuestionShortTitles);
                return {
                  itemsToBeRendered: a.data.data,
                  totalNumberOfItemsMatchingCriteria: a.data.count
                };
              });
          }}
          defaultSort={[{ key: "created_at_ms", dir: "desc" }]}
          extraDeps={[selectedOrgSeasonId, p.org.id]}
          columns={{
            name: {
              label: translate({ defaultMessage: "Player Name" }),
              getCell(item: OrgRegistrationAnswerTableData) {
                return `${item.firstName} ${item.lastName}`;
              },
              sortable: true
            },
            created_at_ms: {
              label: translate({ defaultMessage: "Registration Date" }),
              getCell(item: OrgRegistrationAnswerTableData) {
                return dateFormatters.m_d_yy_t_tt_a(moment(item.createdAtMS), getCurrentLocale());
              },
              sortable: true
            },
            team_name: {
              label: translate.common.Team,
              getCell(item: OrgRegistrationAnswerTableData) {
                return item.teamName;
              },
              sortable: true
            },
            ...allQuestionShortTitles.reduce((acc, val) => {
              acc[val] = {
                label: val,
                getCell(item) {
                  const answer = item.answers.find(ans => {
                    return ans.questionSnapshot.shortTitle === val;
                  });
                  if (answer) {
                    let responseString = "";
                    if (answer) {
                      switch (answer.type) {
                        case OrgRegistrationQuestionType.checkbox:
                          responseString = answer.selections
                            .map(s => {
                              return s.replace(/\n/g, ` `).replace(/,/g, ` `);
                            })
                            .join(":");
                          break;
                        case OrgRegistrationQuestionType.radio:
                          responseString = answer.selection.replace(/\n/g, ` `).replace(/,/g, ` `);
                          break;
                        case OrgRegistrationQuestionType.freeResponse:
                          responseString = answer.response.replace(/\n/g, ` `).replace(/,/g, ` `);
                          break;
                        case OrgRegistrationQuestionType.legalDoc:
                          responseString = `${moment(answer.agreedToAtMS).format("MM/DD/YYYY")}`;
                          break;
                      }
                    }
                    return responseString;
                  }
                  return "";
                }
              };
              return acc;
            }, {} as Record<string, ColumnDef<OrgRegistrationAnswerTableData>>)
          }}
          renderFiltersWrapper={filters => <div className="my-4 flex flex-row items-center">{filters}</div>}
          renderFilters={{
            search: a => (
              <CoolTextInput
                style={{ width: 500 }}
                value={a.valueImmediate}
                isLoading={a.value !== a.valueImmediate || a.isTableFetching}
                placeholder={translate({ defaultMessage: "Search by player name" })}
                onChange={newVal => {
                  if (newVal) {
                    a.setValueDebounced(newVal, 500, { leading: false, trailing: true });
                  } else {
                    a.setValue("");
                  }
                }}
              />
            ),
            actions: a => (
              <div className="ml-4">
                <ActionButtonDropdown disabled={actionOptions.length === 0} actions={actionOptions}>
                  <span style={{ fontWeight: "normal", marginRight: 6 }}>
                    {translate({ defaultMessage: "Actions" }, { num: numSelectedPlayers })}
                  </span>
                  <SvgIcon style={{ paddingRight: 6 }}>
                    <MoreHorizontal />
                  </SvgIcon>
                </ActionButtonDropdown>
              </div>
            )
          }}
          initialFilterValues={{
            search: "",
            actions: ""
          }}
        />
      ) : null}
    </div>
  );
}
