import _ from "lodash";
import { Org, OrgRegistrationQuestion, OrgRegistrationQuestionGrouping, OrgSeason } from "@ollie-sports/models";
import { translate } from "@ollie-sports/i18n";

import { Form, PrettyCoolDateInput, PrettyCoolTextInputWithLabel } from "../../components/Form";
import moment from "moment";
import { openModal } from "../../components/modals/imperativeModal";
import { useImmutableState } from "../../utils/useImmutableState";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { dequal } from "dequal";
import { useOrgSeasons } from "../../hooks/useOrgSeasons";
import CoolerTable from "../../components/CoolerTable";
import { getBifrost } from "../../services/bifrost.service";
import { CoolCheckboxInput } from "../../components/Inputs/CoolCheckboxInput";
import { cleanlyCutString } from "../../utils/cleanlyCutString";
import { registrationQuestionTypeLabels } from "../../constants";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import { useRef, useState } from "react";
import { TextButton } from "../../components/TextButton";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { openSelectOrgQuestions } from "../../utils/openSelectOrgQuestions";
import { SafeButton } from "../../components/SafeButton";
import { DragIndicator } from "@material-ui/icons";
import { Menu } from "react-feather";
import clsx from "clsx";
type Props = {
  type: "create" | "edit";
  org: Org;
  intialGrouping?: OrgRegistrationQuestionGrouping;
};

export function openOrgRegistrationQuestionGroupingAddEditModal(p: Props) {
  const modal = openModal({
    body: (
      <OrgRegistrationQuestionGroupingAddEditModal
        {...p}
        onRequestDismiss={() => {
          modal.close();
        }}
      />
    )
  });

  return modal;
}

function OrgRegistrationQuestionGroupingAddEditModal(p: Props & { onRequestDismiss: () => void }) {
  const { data: orgQuestions } = getBifrost().orgRegistrationQuestion__client__getAllForOrgIdSubscription.useClientSubscription({
    orgId: p.org.id
  });

  const [dragOverItemId, setDragOverItemId] = useState("");

  const orgQuestionsMap =
    orgQuestions?.reduce((acc, a) => {
      acc[a.id] = a;
      return acc;
    }, {} as Record<string, OrgRegistrationQuestion>) || {};

  const initialGrouping: Partial<OrgRegistrationQuestionGrouping> = p.intialGrouping ?? {};
  const [grouping, modifyGrouping] = useImmutableState(initialGrouping);

  const draggingItemId = useRef("");

  const sortedQuestionIds = Object.keys(grouping.questionIds || {})
    .sort((a, b) => grouping.questionIds![a].sortOrder - grouping.questionIds![b].sortOrder)
    .map(a => grouping.questionIds![a]);

  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={
              p.type === "create"
                ? translate({ defaultMessage: "Create Question Grouping" })
                : translate({ defaultMessage: "Edit Question Grouping" })
            }
            bottomButton={{
              title: p.type === "edit" ? translate.common.Save : translate.common.Create,
              enabled: isFormValid && !!Object.keys(grouping.questionIds || {}).length,
              onPress: async () => {
                if (p.type === "create") {
                  await getBifrost().orgRegistrationQuestionGrouping__client__add.fetchClient({
                    doc: {
                      ...grouping,
                      orgId: p.org.id
                    } as any
                  });
                } else {
                  await getBifrost().orgRegistrationQuestionGrouping__client__update.fetchClient({
                    id: p.intialGrouping!.id!,
                    shallowChanges: grouping
                  });
                }
                p.onRequestDismiss();
              }
            }}
            onRequestDismiss={() => {
              if (
                !dequal(grouping, initialGrouping) &&
                !window.confirm(translate({ defaultMessage: "You have unsaved changes. Are you sure you wish to leave?" }))
              ) {
                return;
              }

              p.onRequestDismiss();
            }}
          >
            <div>
              <PrettyCoolTextInputWithLabel
                label={translate({ defaultMessage: "Question Group Name" })}
                value={grouping.name}
                isRequired
                inputProps={{
                  placeholder: translate({ defaultMessage: "E.g. LFC North Questions" })
                }}
                onChange={newVal => {
                  modifyGrouping({ name: newVal });
                }}
              />
            </div>
            <h3 className="text-sm font-extrabold mb-1 mt-4 flex">{translate({ defaultMessage: "Questions" })}</h3>

            <div className="flex flex-col">
              {sortedQuestionIds
                .map(a => orgQuestionsMap[a.id])
                .filter(Boolean)
                .map(a => {
                  return (
                    <div
                      key={a.id}
                      className="pb-1"
                      onDragOver={e => {
                        e.dataTransfer.dropEffect = "link";
                        e.preventDefault();
                        setDragOverItemId(a.id);
                      }}
                    >
                      <div
                        className={clsx("flex border shadow-sm rounded-md", a.id === dragOverItemId && "bg-green-100")}
                        draggable
                        onDrop={() => {
                          const thisIndex = sortedQuestionIds.findIndex(b => b.id === a.id);
                          const movingItemIndex = sortedQuestionIds.findIndex(b => b.id === draggingItemId.current);

                          let newSortedQuestionIds = sortedQuestionIds.slice();
                          // Remove the item from its old position and insert it at the new position
                          const movingItem = newSortedQuestionIds.splice(movingItemIndex, 1)[0];
                          newSortedQuestionIds.splice(thisIndex, 0, movingItem);
                          newSortedQuestionIds = newSortedQuestionIds.map((b, i) => ({ ...b, sortOrder: i }));

                          modifyGrouping({
                            questionIds: newSortedQuestionIds.reduce((acc, b) => {
                              (acc as any)[b.id] = b;
                              return acc;
                            }, {})
                          });
                        }}
                        onDragEnd={() => {
                          setDragOverItemId("");
                        }}
                        onDragStart={() => {
                          draggingItemId.current = a.id;
                        }}
                      >
                        <div className="p-4 cursor-move">
                          <Menu className="h-4 w-4 text-gray-400" />
                        </div>
                        <div className="py-4 flex-1 flex items-center cursor-move">
                          <span>{a.shortTitle}</span>
                        </div>
                        <button
                          className="p-4"
                          onClick={() => {
                            modifyGrouping(s => {
                              delete s.questionIds![a.id];
                            });
                          }}
                        >
                          <TrashIcon className="h-4 w-4 text-red-400" />
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>

            <TextButton
              className="mt-2"
              iconElm={<PlusIcon width={24} height={24} className="text-green-400" />}
              text={translate({ defaultMessage: "Add Question" })}
              textClassName="text-green-400"
              onPress={async () => {
                const newQuestions = await openSelectOrgQuestions({
                  unselectedOrgQuestions: orgQuestions?.filter(a => !grouping.questionIds?.[a.id]?.exists) || []
                });

                if (newQuestions?.length) {
                  modifyGrouping(s => {
                    const currentNum = Math.max(...Object.values(s.questionIds || {}).map(a => a.sortOrder), 0);
                    s.questionIds = s.questionIds || {};
                    newQuestions.forEach((id, i) => {
                      s.questionIds![id] = {
                        exists: true,
                        id: id,
                        sortOrder: i + 1 + currentNum
                      };
                    });
                  });
                }
              }}
            />
          </FullScreenModal>
        );
      }}
    />
  );
}
