import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { COLORS, compute, getOrgRegistrationQuestionMainBodyText } from "@ollie-sports/core";
import { translate } from "@ollie-sports/i18n";
import _ from "lodash";
import CoolerTable from "../../components/CoolerTable";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import getConfirm from "../../components/modals/getConfirm";
import { registrationQuestionTypeLabels } from "../../constants";
import { getBifrost } from "../../services/bifrost.service";
import { cleanlyCutString } from "../../utils/cleanlyCutString";
import { openErrorToast } from "../../utils/openErrorToast";
import { wrapPromiseWithLoader } from "../../utils/wrapPromiseWithLoader";
import { openAddEditOrgRegistrationQuestion } from "./AddEditOrgRegistrationQuestion";
import { ORG_PERMISSIONS, Org, OrgRegistrationQuestion, OrgRegistrationQuestionGrouping } from "@ollie-sports/models";
import { useState } from "react";
import { getCurrentUserAccountId } from "../../hooks/commonDataUtils";

export function RegistrationQuestionsTable(p: {
  orgQuestionGroupings: OrgRegistrationQuestionGrouping[];
  orgQuestions: OrgRegistrationQuestion[];
  org: Org;
}) {
  const [searchInput, setSearchInput] = useState("");
  return (
    <CoolerTable
      style={{ marginTop: 16 }}
      items={p.orgQuestions}
      defaultSortSettings={{ dir: "asc", label: translate.common.Title }}
      noItemsMessage={translate({ defaultMessage: "No registration questions created yet..." })}
      noFilteredItemsMessage={translate({ defaultMessage: "No registration questions matching selected filters..." })}
      columnDefs={[
        {
          label: translate.common.Title,
          getValue: item => item.shortTitle,
          sortItems: (items, dir) => {
            return _.orderBy(items, a => a.shortTitle.toLowerCase(), dir);
          }
        },

        {
          getValue: item => cleanlyCutString(getOrgRegistrationQuestionMainBodyText(item), 250),
          label: translate({ defaultMessage: "Question/Text" }),
          sortItems: (items, dir) => {
            return _.orderBy(items, item => getOrgRegistrationQuestionMainBodyText(item).toLowerCase(), dir);
          }
        },

        {
          label: translate({ defaultMessage: "Type" }),
          getValue: item => registrationQuestionTypeLabels[item.type],
          sortItems: (items, dir) => {
            return _.orderBy(items, item => registrationQuestionTypeLabels[item.type].toLowerCase(), dir);
          }
        },
        {
          label: translate({ defaultMessage: "Question Groups" }),
          getValue: item => {
            return (
              <div className="flex flex-wrap gap-1">
                {p.orgQuestionGroupings
                  .filter(a => !!a.questionIds[item.id])
                  .map(a => (
                    <div className="text-sm p-2 bg-gray-400  text-white rounded-lg whitespace-nowrap" key={a.id}>
                      {a.name}
                    </div>
                  ))}
              </div>
            );
          },
          sortItems: a => {
            return a;
          }
        },
        {
          label: translate({ defaultMessage: "Is Required" }),
          getValue: item => ("isRequired" in item && item.isRequired ? translate.common.Yes : ""),
          sortItems: a => {
            return a;
          }
        }
      ]}
      rowButtons={
        compute.org.hasOrgPermission({
          accountId: getCurrentUserAccountId(),
          org: p.org,
          permission: ORG_PERMISSIONS.manageFinances
        })
          ? [
              {
                getIcon: () => <PencilIcon color={COLORS.blue_66} />,
                getLabel: () => translate.common.Edit,
                type: "inline",
                onClick: async item => {
                  openAddEditOrgRegistrationQuestion({
                    org: p.org,
                    type: "edit",
                    intialOrgQuestion: item
                  });
                }
              },
              {
                getIcon: () => <TrashIcon color={COLORS.red_66} />,
                getLabel: () => translate.common.Delete,
                type: "inline",
                async onClick(item) {
                  const {
                    data: { packageGroups, packages }
                  } = await wrapPromiseWithLoader(
                    getBifrost().orgRegistrationQuestion__client__getRelatedDocs.fetchClient({
                      id: item.id,
                      orgId: item.orgId
                    })
                  );

                  const confirm = await getConfirm({
                    confirmButtonColor: "red",
                    subtitle: [
                      translate({
                        defaultMessage: "Are you sure you want to delete this registration question?"
                      }),
                      packageGroups.length || packages.length
                        ? translate({
                            defaultMessage:
                              "It will be removed from the registration package(s) and question group(s) it has been assigned to."
                          })
                        : ""
                    ]
                      .filter(Boolean)
                      .join(" "),
                    title: translate({ defaultMessage: "Delete Question?" })
                  });
                  if (confirm) {
                    try {
                      await getBifrost().orgRegistrationQuestion__client__delete.fetchClient({
                        id: item.id,
                        orgId: item.orgId
                      });
                    } catch (e) {
                      openErrorToast(translate({ defaultMessage: "Problem deleting payment plan!" }));
                    }
                  }
                }
              }
            ]
          : []
      }
      filters={[
        {
          filterComponent: (
            <CoolTextInput
              value={searchInput}
              onChange={newVal => {
                setSearchInput(newVal ?? "");
              }}
              showClearButton
              inputProps={{
                placeholder: translate.common.FilterVerb + "..."
              }}
            />
          ),
          onFilter: items => {
            const terms = searchInput.toLowerCase().split(/ +/);
            return items.filter(item => {
              const body = cleanlyCutString(getOrgRegistrationQuestionMainBodyText(item), 250);
              const haystack = [item.shortTitle, body].join(" ").toLowerCase();
              return terms.every(term => haystack.includes(term));
            });
          }
        }
      ]}
      getItemKey={item => item.id}
    />
  );
}
